//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import { registerHospital } from "@/lib/polkadotProvider/command/hospitals";
import countryData from "@/assets/json/country.json";
import cityData from "@/assets/json/city.json";
import { upload } from "@/lib/ipfs";
export default {
  name: "HospitalRegistration",
  data: () => ({
    country: "",
    region: "",
    city: "",
    countries: [],
    cities: [],
    regions: [],
    email: "",
    hospitalName: "",
    address: "",
    image: "",
    files: [],
    isLoading: false,
    isUploading: false
  }),
  async mounted() {
    await this.getCountries();
  },
  computed: {
    ...mapGetters({
      api: "substrate/getAPI",
      pair: "substrate/wallet",
      hospitalAccount: "substrate/hospitalAccount",
      isHospitalAccountExist: "substrate/isHospitalAccountExist"
    }),
    citiesSelection() {
      return this.cities.filter(c => c.country == this.country).map(c => ({
        value: c.city,
        text: c.city,
        country: c.country
      }));
    },
    selectedHospital() {
      if (!this.hospitalAccount) {
        return;
      }
      return this.hospitals.filter(l => l.hospitalAccount == this.hospitalAccount)[0];
    },
    emailRules() {
      return [val => !!val || "This field is required", val => /.+@.+\..+/.test(val) || "Email is invalid. It should contain @ followed by a domain"];
    },
    nameRules() {
      return [val => !!val || "This field is required"];
    },
    addressRules() {
      return [val => !!val || "This field is required", val => val && val.length <= 180 || "This field only allows 180 characters"];
    },
    fileInputRules() {
      return [value => !value || value.size < 2000000 || "The total file size uploaded exceeds the maximum file size allowed (2MB)"];
    }
  },
  methods: {
    setHospitalAccount() {
      this.$store.state.substrate.isHospitalAccountExist = true;
    },
    async getCountries() {
      this.countries = countryData;
    },
    onCountryChange(selectedCountry) {
      this.country = selectedCountry;
      this.regions = Object.entries(cityData[this.country].divisions);
    },
    onRegionChange(selectedRegion) {
      this.region = selectedRegion;
      this.cities = Object.entries(cityData[this.country].divisions);
    },
    onCityChange(selectedCity) {
      this.city = selectedCity;
    },
    async registerHospital() {
      if (!this.validating()) {
        return;
      }
      try {
        this.isLoading = true;
        async () => {
          await registerHospital(this.api, this.pair, {
            name: this.hospitalName,
            email: this.email,
            profileImage: this.image,
            address: this.address,
            country: this.country,
            city: this.city
          }, async () => {
            this.setHospitalAccount();
            this.isLoading = false;
            this.$router.push("/hospital");
          });
        };
      } catch (e) {
        console.error(e);
      }
    },
    fileUploadEventListener(file) {
      this.isUploading = true;
      this.isLoading = true;
      if (file) {
        if (file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsArrayBuffer(file);
        const context = this;
        fr.addEventListener("load", async () => {
          // Upload
          const uploaded = await upload({
            fileChunk: fr.result,
            fileType: file.type,
            fileName: file.name
          });
          const computeLink = "".concat(uploaded.ipfsPath[0].data.ipfsFilePath, "/").concat(uploaded.fileName);
          context.image = "https://ipfs.io/ipfs/".concat(computeLink); // this is an image file that can be sent to server...
          context.isUploading = false;
          context.isLoading = false;
        });
      } else {
        this.files = [];
        this.image = "";
      }
    },
    validating() {
      if (this.hospitalName == "" || this.email == "" || this.image == "" || this.address == "" || this.country == "" || this.city == "") {
        this.$refs.hospitalForm.validate();
        return false;
      }
      return true;
    }
  }
};